@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Josefin Sans", sans-serif;
}

* {
  margin: 0;
}

p {
  font-size: 1.2rem;
  font-weight: 300;
}

h1 {
  font-size: 2.6rem;
  font-weight: 700;
}

h2 {
  font-size: 2.2rem;
  font-weight: 600;
}

h3 {
  font-size: 2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.8rem;
  font-weight: 400;
}

h5 {
  font-size: 1.6rem;
  font-weight: 300;
}

h6 {
  font-size: 1.5rem;
  font-weight: 300;
}
